import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Layout from './Layout'
import MoreCategories from './MoreCategories'

import StoreContext from '../context/StoreContext'

import db from '../utils/db'
import fbTracking from '../utils/fb-tracking'
import Helmet from 'react-helmet'
import getStoreFromPath from '../utils/getStoreFromPath'

import isClose from '../utils/isClose'

const Main = styled.main`
    margin: 1rem var(--spacing-global);
`

const addProductToOrder = (order, item) => {
    const { tracking, ...product } = item
    if (order.length) {
        for (let i = 0; i < order.length; i++) {
            const item = order[i]

            if (item.Nombre === product.Nombre) {
                item.quantity += 1
                return [...order]
            }
        }
        return [
            ...order,
            {
                ...product,
                quantity: product.quantity || 1,
            },
        ]
    }

    return [
        {
            ...product,
            quantity: product.quantity || 1,
        },
    ]
}

const removeProductFromOrder = (order, product) => {
    return order.filter(item => item.Nombre !== product.Nombre)
}

const StoreLayout = ({
    ContentComponent,
    children,
    location,
    seoimage,
    seotitle,
    seourl,
    hideCategories = false,
}) => {
    const store = getStoreFromPath(location.pathname)
    const [order, setOrder] = useState(db.order.get(store))
    const [close, setClose] = useState(false)

    const Content = ContentComponent || Main

    useEffect(() => {
        async function getClose() {
            const closed = await isClose(store)
            setClose(closed)
        }
        getClose()
    }, [store])

    return (
        <StoreContext.Provider
            value={{
                store: store,
                order: order,
                isClose: close,
                addItem: (product, track = true) => {
                    const updatedOrder = addProductToOrder(order, product)
                    setOrder(updatedOrder)
                    db.order.set(store, updatedOrder)

                    if (track) {
                        if (product.REF === 'CUSTOM_PRODUCT') {
                            fbTracking('track', 'CustomizeProduct')
                        } else {
                            fbTracking('track', 'AddToCart', product.tracking)
                        }
                    }
                },
                removeItem: product => {
                    const updatedOrder = removeProductFromOrder(order, product)
                    setOrder(updatedOrder)
                    db.order.set(store, updatedOrder)
                },
                clearOrder: () => {
                    setOrder([])
                    db.order.set(store, [])
                },
            }}
        >
            <Helmet
                htmlAttributes={{
                    class: store,
                }}
            />

            <Layout
                location={location}
                seourl={seourl}
                seoimage={seoimage}
                seotitle={seotitle}
                isStore={true}
            >
                <Content>{children}</Content>
                {/* {!hideCategories && <MoreCategories />} */}
            </Layout>
        </StoreContext.Provider>
    )
}

export default StoreLayout
