import React from 'react'
import Link from 'gatsby-link'

import StoreLayout from '../components/StoreLayout'

import styled from 'styled-components'

const Content = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export default ({ location }) => (
    <StoreLayout location={location} seotitle="404: Página no encontrada">
        <Content>
            <h1>Ooops, la página que estas buscando no existe</h1>
            <p>
                No te preocupés, puedes ir al{' '}
                <Link to="/">inicio de la página</Link> o...
            </p>
        </Content>
    </StoreLayout>
)
