const db = {
    order: {
        get: store => {
            const itemName = `${store}-order`
            const order = process.browser && localStorage.getItem(itemName)
            return order ? JSON.parse(order) : []
        },
        set: (store, order) => {
            const itemName = `${store}-order`
            process.browser &&
                localStorage.setItem(itemName, JSON.stringify(order))
        },
    },
}

export default db
