import React from 'react'
import styled from 'styled-components'
import MenuCategories from './Menu/MenuCategories'


const Wrapper = styled.div`
    border-top: solid 1px ${props => props.theme.gray};
    padding-top: 2rem;
    text-align: center;

    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ul {
        max-width: calc(900px + var(--spacing-global));
        margin: 0 auto;
        padding: 0 var(--spacing-global);
        display: grid;
        grid-template-columns: repeat(
            auto-fill,
            minmax(var(--product-box-size), 1fr)
        );
        grid-gap: 1rem;
        align-items: center;
    }

    a {
        border-radius: 3rem;
        border: solid 2px ${props => props.theme.brandColor1};
        color: solid 1px ${props => props.theme.brandColor1};
        padding: 0.1rem 0.5rem;
        text-decoration: none;
        white-space: pre-wrap;
    }

    li:first-child {
        display: none;
    }
`

const MoreCategories = () => {
    return (
        <Wrapper>
            <h3>Buscar por Categorías</h3>
            <MenuCategories />
        </Wrapper>
    )
}

export default MoreCategories
